<script>
import Layout from "../../layouts/main";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
const clientController = require('@/controllers/endpoint/clients')
const brandController = require('@/controllers/endpoint/brands')
const userController = require('@/controllers/endpoint/user')
import "@/assets/scss/buttons_content.scss";

export default {
  components: {
    Layout,
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      title:'',
      basepathENDPOINT: process.env.VUE_APP_BASEPATH_ENDPOINT,
      user:{},
      clientId:this.$route.params.id,  
      client:{},
      brands:[],
      page: 1,
      brand:{
        name:'',
        description:"",
        website:"",
        rol:"",
        client_id:this.$route.params.id
      },
      brandData:{
        id:"",
        name:"",
        image_data:""
      },
      titleModal:"",
      image:"",
      spinner:true,
      dropzoneOptions: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 500,
        maxFilesize:10,
        maxFiles: 1,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        headers: { "My-Awesome-Header": "header value" }
      },
    };
  },
  created() {
    this.userData()
    this.clientData()
    this.brandsData()
  },
  methods: {
    userData(){
        const dataUser = userController.dataUser();
        dataUser.then((response) => {   //Succesfully result
        this.user = response.data[0];
        this.brand.rol = this.user.rol

    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    clientData(){
        const dataClient = clientController.findID(this.clientId);
        dataClient.then((response) => {   //Succesfully result
        this.client = response.data[0];
        this.title = this.client.name.toUpperCase()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
     brandsData(){
        
        const dataBrands = brandController.listPaginate(this.page, this.clientId);
        dataBrands.then((response) => {
        this.brands = response.data.rows
        setTimeout(() => this.spinner = false, 500);
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
     brandLink(id, name){
        name = name.replace(/ /g,"-").toLowerCase();
        const link = `/brand/${name}/${id}`
        this.$router.push(link)
    },
     addBrand(){
        this.brand.website = this.brand.website.replace('https://', '')
        this.brand.website = this.brand.website.replace('http://', '')
        this.brand.name = this.brand.name.toUpperCase()
        const addBrand = brandController.create(this.brand);
        addBrand.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.alert(data, 'success')
        this.closeModal()
        this.resetData()
        this.spinner = true;
        this.brandsData()
    })
      .catch((err) => { 
         this.alert(err, 'danger')   //Error result
      });
    },
    deleteBrand(id){
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            let data =    {
                "id": id,
                "rol":this.user.rol
            }
        const deleteBrand = brandController.delete(data);
        deleteBrand.then((response) => {   //Succesfully result
        response
        swalWithBootstrapButtons.fire(
              "Deleted!",
              "Your brand has been deleted.",
              "success"
            );
        this.resetData()
        this.spinner = true;
        this.brandsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your brand is safe :)",
              "error"
            );
          }
        });
    },
    alert(message, variant){
          this.$bvToast.toast(`${message}`, {
        title: "BRAND ALERT",
        variant: variant,
        solid: true
      });
    },
    closeModal(){
        this.$refs['new-brand-modal'].hide()
    },
    resetData(){
        this.brand ={
        name:"",
        description:"",
        website:"",
        rol:this.user.rol,
        client_id:this.$route.params.id
        }
        this.brands = []
    },
   spinnerStatus(status){
     setTimeout(() => this.spinner = status, 500);
   },
   modal_add_Image(name, id){
     this.brandData.name = name
     this.brandData.id = id
     this.titleModal = `CHANGE IMAGE ${this.brandData.name}`
     this.titleModal = this.titleModal.toUpperCase()
     this.$refs['add-image-modal'].show()
   },
   convertImage(file){
        return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        reader.onload = function() { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
   },
   getImage(file) {
      const base64 = this.convertImage(file);
      base64.then((response) => { 
        this.brandData.image_data = response
    })
    },
   addImage() {
const addBrand = brandController.upload_image(this.brandData);
        addBrand.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.alert(data, 'success')
        this.resetData()
        this.spinner = true;
        this.brandsData()
        this.brandsData.image_data = ""
        this.$refs['add-image-modal'].hide()

    })
      .catch((err) => { 
         this.alert(err, 'danger')   //Error result
      });
   },
   modal_edit_Brand(id, name, description, website){
      this.brand = {
        "id" : id, 
        "description" : description,
        "name" : name, 
        "website" : website
       };
      this.brand.rol = this.user.rol 
      this.$refs['edit-brand-modal'].show()
   },
   editBrand(){
        this.brand.website = this.brand.website.replace('https://', '')
        this.brand.website = this.brand.website.replace('http://', '')
        this.brand.name = this.brand.name.toUpperCase()
        const addBrand = brandController.update(this.brand);
        addBrand.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.alert(data, 'success')
        this.closeModal()
        this.resetData()
        this.spinner = true;
        this.brandsData()
    })
      .catch((err) => { 
         this.alert(err, 'danger')   //Error result
      });
   },
   modal_new_Brand(){
    this.brand ={
        name:"",
        description:"",
        website:"",
        rol:this.user.rol,
        client_id:this.$route.params.id
    }
    this.$refs['new-brand-modal'].show()
   }
  },
  computed:{
    disabledForm(){
            return this.brand.name == '' || this.brand.description == ''
    },
    disabledImageForm(){
            return this.brandData.image_data == ''
    }
  },
};
</script>
<style scoped>
.dz-progress{
display: none;
}
.title-brands{
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  font-family: nunito;
  font-weight: 600;
  pointer-events: none;
}
.dot-brands{
  pointer-events: none;
  color:#247ba0;
  font-family: nunito;
  font-weight: 600
}
.anchore-menu{
color:#011c27;
font-weight: bold;
font-family: "Inter", sans-serif;
font-weight: 500;
}
.anchore-menu:hover{
color:#247ba0;
font-weight: bold;
font-family: "Inter", sans-serif;
font-weight: 500;
}
.second-menu{
pointer-events: none;
color:#595959;
font-family: "Inter", sans-serif;
font-weight: 500;
}
.card-brands {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 40px;
    background-color: #011c27;
    border: 2.5px solid #011c27;
}
body{
    background-color: #ffffff;
}
.icons-brands{
    color:#f9faff;
    cursor: pointer;
}
.icons-brands:hover{
    color:#247ba0 !important;
}
.icons-brands-image{
    color:#f9faff;
    cursor: pointer;
}
.icons-brands-image:hover{
    color:#f9faff !important;
}
.button-brands{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-brands:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #f9faff !important;
    background-color: #011c27 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
.button-brands-form{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-brands-form:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #011c27 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
.brand-image{
    background-color:#f9faff; 
    height: 110px; 
    margin-bottom:20px; 
    padding:8px;  
    border: 4px solid #247ba0;
}
.container {
  position: relative;
  width: 50%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: transparent;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.button-new-brand{
    border: 2px solid #247ba0;
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-new-brand:hover{
    border: 2px solid #011c27;
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
</style>
<template>
  <Layout>
      <div style="padding-left:30px; padding-right:30px;">
      <div class="text-lg-left">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
                <router-link to="/clients" class="font-w600 text-dual">
<a class="anchore-menu">Clients</a>
                </router-link>
            </li>
            <li class="list-inline-item">
        <i style="color:#3521b5;" class="fas fa-angle-right"></i>
            </li>
            <li class="second-menu list-inline-item">
       Brands
            </li>          
          </ul>
        </div>
        <div class="row">
            <div  class="col-lg-6 text-lg-left">
          <h5 class="title-brands">Brands of <span class="dot-brands">{{title}}</span>.</h5>
            </div>
<div  v-if="user.rol > 0 && user.rol <= 8" class="button-div col-lg-6 text-lg-right">
<button v-on:click="modal_new_Brand()" class="button-new-brand" style="">Add new brand</button>
            <b-modal v-if="user.rol > 0 && user.rol <= 8" ref="new-brand-modal" id="popup-form" hide-footer centered title="NEW BRAND">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="name">Brand Name<span style="color:#247ba0">*</span></label>
                      <input v-model="brand.name" type="text" class="form-control" id="name" placeholder="Enter Brand Name" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="description">Brand Description<span style="color:#247ba0">*</span></label>
                      <input v-model="brand.description" type="text" class="form-control" id="description" placeholder="Enter Brand Description" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                        <label for="website">Brand Website</label>
                         <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text">https://</span>
                      </div>
                      <input
                      v-model="brand.website"
                        id="validationTooltipUsername"
                        type="text"
                        class="form-control"
                        placeholder="Enter Brand Website"
                        aria-describedby="validationTooltipUsernamePrepend"
                      />
                    </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-left">
                      <button v-on:click="addBrand()" :disabled='disabledForm' class="btn button-brands-form btn-primary">ADD NEW</button>
                    </div>
                  </div>
                </div>
            </b-modal>
            <b-modal ref="add-image-modal" hide-footer centered :title="`${titleModal}`">
                {{image}}
  <vue-dropzone  :options="dropzoneOptions" ref="myVueDropzone" id="dropzone" @vdropzone-file-added="getImage" ></vue-dropzone>
 <div class="text-left" style="padding-top:10px;">
                      <button v-on:click="addImage()" :disabled='disabledImageForm' class="btn button-brands-form btn-primary">ADD NEW</button>
</div>
</b-modal>
          <b-modal v-if="user.rol > 0 && user.rol <= 8" ref="edit-brand-modal" id="edit-popup-form" hide-footer centered title="EDIT BRAND">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="name">Brand Name<span style="color:#247ba0">*</span></label>
                      <input v-model="brand.name" type="text" class="form-control" id="name" placeholder="Enter Brand Name" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="description">Brand Description<span style="color:#247ba0">*</span></label>
                      <input v-model="brand.description" type="text" class="form-control" id="description" placeholder="Enter Brand Description" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                        <label for="website">Brand Website</label>
                         <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text">https://</span>
                      </div>
                      <input
                      v-model="brand.website"
                        id="validationTooltipUsername"
                        type="text"
                        class="form-control"
                        placeholder="Enter Brand Website"
                        aria-describedby="validationTooltipUsernamePrepend"
                      />
                    </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-left">
                      <button v-on:click="editBrand()" :disabled='disabledForm' class="btn button-brands-form btn-primary">UPDATE BRAND</button>
                    </div>
                  </div>
                </div>
            </b-modal>
            </div>
        </div>
        <div style="padding-top:150px; " v-if="spinner == true" class="text-center">
            <b-spinner style="width: 3rem; height: 3rem; color:#011c27;" type="grow" label="Text Centered Large Spinner"></b-spinner>
        </div>
    <div v-if="spinner == false"  class="row">
            
      <div class="col-xl-3 col-sm-6" v-for="(data, index) in brands" :key="index">
        <div class="card card-brands">
          <div class="card-body">
            <div  class="text-center">
              <img  v-if="user.rol >= 9 && data.image_url == null"  src="@/assets/images/image-brand.png" alt class="brand-image rounded-circle" style="width:100px; height:100px;"/>
              <img  v-if="user.rol >= 9 && data.image_url !== null"  :src="`${basepathENDPOINT}${data.image_url}`" alt class="brand-image rounded-circle" style="width:100px; height:100px;"/>
              <div  v-if="user.rol > 0 && user.rol <= 8" class="container">
  <img v-if="data.image_url == null" src="@/assets/images/image-brand.png" alt="Avatar" class="image brand-image rounded-circle" style="width:100px; height:100px;">
   <img v-if="data.image_url !== null" :src="`${basepathENDPOINT}${data.image_url}`" alt="Avatar" class="image brand-image rounded-circle" style="width:100px; height:100px;">
  <div class="middle">
    <div class="text rounded-circle"><i  v-on:click="modal_add_Image(data.name, data.id)" class="icons-brands-image fas fa-pen"></i></div>
  </div>
</div>
              <div class="media-body">
                <h5 style="color:#f9faff; pointer-events: none;" class="text-truncate">
                  {{data.name}}
                </h5>
                <h5 style="color:#4d4d4d; pointer-events: none;" class="text-truncate">
                  {{data.description}}
                </h5>
                  <span  v-if="user.rol >= 9 && data.website !== ''"   class="row">
                    {{data.website}}
                  <div v-if="data.website !== ''" class="col-lg-6 text-lg-right"><a :href="`https://${data.website}`"  target="_blank"><i class="icons-brands fas fa-globe-americas"></i></a></div>
                  <div  class="col-lg-6 text-lg-left"><i v-on:click="brandLink(data.id, data.name)" class="icons-brands fas fa-eye"></i></div>
          </span>
          <span  v-if="user.rol >= 9  && data.website == ''"   class="row">
                  <div  class="col-lg-12"><i v-on:click="brandLink(data.id, data.name)" class="icons-brands fas fa-eye"></i></div>
          </span>
          <span v-if="user.rol > 0 && user.rol <= 8 && data.website !== ''" class="row">
                  <div class="col-lg-4 text-lg-right"><a :href="`https://${data.website}`"  target="_blank"><i class="icons-brands fas fa-globe-americas"></i></a></div>
                  <div  class="col-lg-2 text-lg"><i v-on:click="brandLink(data.id, data.name)" class="icons-brands fas fa-eye"></i></div>
                  <div  class="col-lg-2 text-lg"><i v-on:click="modal_edit_Brand(data.id, data.name, data.description, data.website)" class="icons-brands fas fa-pen"></i></div>  
                  <div  class="col-lg-4 text-lg-left"><i v-on:click="deleteBrand(data.id)" class="icons-brands fas fa-trash"></i></div> 
          </span>
          <span v-if="user.rol > 0 && user.rol <= 8 && data.website == ''" class="row">
                  <div  class="col-lg-5 text-lg-right"><i v-on:click="brandLink(data.id, data.name)" class="icons-brands fas fa-eye"></i></div>
                  <div  class="col-lg-2"><i v-on:click="modal_edit_Brand(data.id, data.name, data.description, data.website)" class="icons-brands fas fa-pen"></i></div>  
                  <div  class="col-lg-5 text-lg-left"><i v-on:click="deleteBrand(data.id)" class="icons-brands fas fa-trash"></i></div> 
          </span>
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="text-center my-3">
        
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>